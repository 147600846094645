// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-announcement-strapi-announcements-strapi-id-js": () => import("./../../../src/pages/announcement/{StrapiAnnouncements.strapiId}.js" /* webpackChunkName: "component---src-pages-announcement-strapi-announcements-strapi-id-js" */),
  "component---src-pages-art-collections-js": () => import("./../../../src/pages/art-collections.js" /* webpackChunkName: "component---src-pages-art-collections-js" */),
  "component---src-pages-book-detail-book-slug-js": () => import("./../../../src/pages/book-detail/[bookSlug].js" /* webpackChunkName: "component---src-pages-book-detail-book-slug-js" */),
  "component---src-pages-cafe-instruments-js": () => import("./../../../src/pages/cafe-instruments.js" /* webpackChunkName: "component---src-pages-cafe-instruments-js" */),
  "component---src-pages-cafesori-js": () => import("./../../../src/pages/cafesori.js" /* webpackChunkName: "component---src-pages-cafesori-js" */),
  "component---src-pages-daegwan-js": () => import("./../../../src/pages/daegwan.js" /* webpackChunkName: "component---src-pages-daegwan-js" */),
  "component---src-pages-event-strapi-events-slug-js": () => import("./../../../src/pages/event/{StrapiEvents.slug}.js" /* webpackChunkName: "component---src-pages-event-strapi-events-slug-js" */),
  "component---src-pages-events-all-js": () => import("./../../../src/pages/events-all.js" /* webpackChunkName: "component---src-pages-events-all-js" */),
  "component---src-pages-events-overview-js": () => import("./../../../src/pages/events-overview.js" /* webpackChunkName: "component---src-pages-events-overview-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-foundation-js": () => import("./../../../src/pages/foundation.js" /* webpackChunkName: "component---src-pages-foundation-js" */),
  "component---src-pages-garden-js": () => import("./../../../src/pages/garden.js" /* webpackChunkName: "component---src-pages-garden-js" */),
  "component---src-pages-hwaban-js": () => import("./../../../src/pages/hwaban.js" /* webpackChunkName: "component---src-pages-hwaban-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../../../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-press-release-strapi-press-releases-strapi-id-js": () => import("./../../../src/pages/press-release/{StrapiPressReleases.strapiId}.js" /* webpackChunkName: "component---src-pages-press-release-strapi-press-releases-strapi-id-js" */),
  "component---src-pages-sanchek-js": () => import("./../../../src/pages/sanchek.js" /* webpackChunkName: "component---src-pages-sanchek-js" */),
  "component---src-templates-announcements-js": () => import("./../../../src/templates/announcements.js" /* webpackChunkName: "component---src-templates-announcements-js" */),
  "component---src-templates-press-releases-js": () => import("./../../../src/templates/press-releases.js" /* webpackChunkName: "component---src-templates-press-releases-js" */)
}

